<template>
  <div class="page-main-box">
    <!-- 有数据 -->
    <div class="pt-24 pl-16 pr-16">
      <!-- 搜索头 -->
      <search-header
        style="display:none;"
        :schemaData="schema"
        @getDataList="handleFormSearch"
      />

      <!-- 列表 -->
      <div :style="{ height: tableHeight + 'px' }">
        <p-table :dataSource="tableDataSource" height="auto">
          <p-t-column type="seq" width="60" title="序号" />
          <p-t-column field="card_no" min-width="150" title="油卡卡号" />
          <p-t-column field="card_type_desc" min-width="126" title="账户类型" />
          <p-t-column field="sp_name" min-width="126" title="服务商" />
          <p-t-column
            field="balance"
            min-width="120"
            title="账户余额"
            align="right"
          >
            <template v-slot="{ row }">
              <span>{{ (row.balance / 100).toFixed(2) }}</span>
            </template>
          </p-t-column>

          <p-t-column
            field="payment_amount"
            min-width="150"
            title="消费金额"
            align="right"
          >
            <template v-slot="{ row }">
              <span>{{ (row.payment_amount / 100).toFixed(2) }}</span>
            </template>
          </p-t-column>

          <p-t-column
            field="order_count"
            min-width="90"
            title="订单数"
            align="right"
          />
          <p-t-column field="status_desc" min-width="90" title="状态" />
          <p-t-column title="绑定时间" field="create_time" min-width="230">
            <template v-slot="{ row }">
              {{
                row.create_time &&
                  formatToDateTime(row.create_time, "yyyy-MM-dd hh:mm:ss")
              }}
            </template>
          </p-t-column>
          <p-t-column width="160" title="操作" fixed="right">
            <template v-slot="{ row }">
              <a-button
                v-if="$store.getters.roles.includes('TransferRecord')"
                type="link"
                @click="handleToTranfer(row)"
              >
                划拨记录
              </a-button>
              <a-button
                v-if="$store.getters.roles.includes('ConsumerOrders')"
                type="link"
                @click="handleToOrder(row)"
              >
                查看订单
              </a-button>
            </template>
          </p-t-column>
        </p-table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, toRaw } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Table } from "@wlhy-web-components/common";
import { searchHeaderOptions } from "./Constants";
import { useGasCardDetailApi } from "@/apis/oil";
import useSearchForm from "@/hooks/UseSearchForm";
import $await from "@/utils/await";
import useTableDataRecords from "@/hooks/useTableDataRecords";
import useTableHeight from "@/hooks/useTableHeight";
import { formatToDateTime } from "@wlhy-web-lib/shared";

export default {
  name: "application-records",
  components: {},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const gasCardDetailApi = useGasCardDetailApi();

    // table高度
    const tableHeight = ref();

    // 列表数据
    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    const { filter, handleFormSearch } = useSearchForm(searchHeaderOptions);

    // 获取列表数据
    Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...toRaw(filter.value),
          driver_ids: route.query.driver_id ? [route.query.driver_id] : [],
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo,
          owner_type: "DRIVER" // 企业端
        };
        let [, res] = await $await(gasCardDetailApi(data));

        return useTableDataRecords(res);
      }
    });

    // 跳转到订单页
    const handleToOrder = row => {
      router.push({
        path: "/oil/consumer-orders",
        query: {
          driver_id: row.owner_id,
          card_no: row.card_no,
          sp_code: row.sp_code
        }
      });
    };

    // 跳转到划拨记录
    const handleToTranfer = row => {
      router.push({
        path: "/oil/transfer-record",
        query: {
          driver_id: row.owner_id,
          card_no: row.card_no
        }
      });
    };

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    return {
      tableHeight,
      tableDataSource,
      formatToDateTime,
      schema: searchHeaderOptions,
      handleFormSearch,
      handleToTranfer,
      handleToOrder
    };
  }
};
</script>

<style lang="less" scoped></style>
